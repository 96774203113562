import React from "react"
import BackgroundImage from "gatsby-background-image"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../../components/layout"
import style from "./index.module.css"
import AndvicHeader from "../../components/andvic-header"

import { useForm } from "react-hook-form"

import FloatingLabel from "../../components/floating-label"
import BurgerMenu from "../../components/burger-menu"
import SEO from "../../components/seo"

const BackgroundSection = ({ className }: { className: any }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "facade.jpg" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data: any) => {
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <>
          {/* <div className={style.overlay}></div> */}
          <BackgroundImage
            Tag="section"
            className={className}
            fluid={imageData}
          >
            <AndvicHeader></AndvicHeader>
            {/* <div className={style.wrap}>
              <div className={style.contactForm}>
                <div>
                  <h1 className={style.header1}>Contact Us</h1>
                  <button>Submit</button>
                </div>
              </div>
            </div> */}
          </BackgroundImage>
        </>
      )
    }}
  />
)

interface IContactForm {
  name: string
  email: string
  phone: string
  enquiry: string
}

export default function IndexPage(): JSX.Element {
  const initialValues: IContactForm = { name: "", email: "", phone: "" }

  const { register, watch } = useForm<IContactForm>()

  const watchAllFields = watch()

  return (
    <Layout>
      <SEO title="Contact" />
      <BurgerMenu></BurgerMenu>
      <BackgroundSection className={style.header}></BackgroundSection>
      <div className={style.mainHeader}>
        <div>
          <h1 className={style.header1}>Get in touch</h1>
        </div>
      </div>
      <div className={style.copy}>
        <div>
          <p className={style.address}>
            5/7 Eden Park Drive
            <br />
            Macquaire Park, NSW, 2113
            <br></br>
            <br></br>
            (02) 8935 9408
          </p>
          {/* <div className={style.form}>
            <form>
              <FloatingLabel
                name="name"
                ref={register}
                placeholder="Your name"
                className={style.contactInput}
                value={watchAllFields.name}
              />
              <FloatingLabel
                name="phone"
                ref={register}
                placeholder="Your phone number"
                type="tel"
                className={style.contactInput}
                value={watchAllFields.phone}
              />
              <FloatingLabel
                name="email"
                ref={register}
                placeholder="Your email"
                type="email"
                className={style.contactInput}
                value={watchAllFields.email}
              />
              <FloatingLabel
                name="enquiry"
                ref={register}
                placeholder="Your enquiry"
                // type="email"
                component="textarea"
                className={style.contactInput}
                value={watchAllFields.enquiry}
              />

              <input className={style.buttonSubmit} type="submit" />
            </form>
          </div> */}
        </div>
      </div>
    </Layout>
  )
}
